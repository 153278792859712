import React, { FC, useCallback, useEffect, useState } from 'react'
import { BrandingProvider } from '~brix/connectors/context/useEventBranding'
import { eventSettingsKeys, CustomFormMessages } from '~constants'
import { Flex } from 'mui-blox'
import { CustomForms } from './CustomForms'
import { useResizeDetector } from 'react-resize-detector'

const HEIGHT_OFFSET = 250

export const CustomFormsBrander: FC<{ eventId: string }> = ({ eventId }) => {
  const [normalizedHeight, setNormalizedHeight] = useState(0)

  const { ref: formRef, height } = useResizeDetector<HTMLFormElement>()

  const handleResizeFrame = useCallback((height: number) => {
    const type = CustomFormMessages.formDimensionsChanged

    window.parent.window.postMessage({ type, height }, window.clientHost)
  }, [])

  useEffect(() => {
    const newHeight = Math.ceil((height || 0) + HEIGHT_OFFSET)

    setNormalizedHeight(newHeight)
  }, [height])

  useEffect(() => {
    handleResizeFrame(normalizedHeight)
  }, [normalizedHeight, handleResizeFrame])

  return (
    <BrandingProvider eventId={eventId} settingKey={eventSettingsKeys.chatbotBranding} disableGlobal>
      <Flex flexFull style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
        <Flex ref={formRef} width='100%' style={{ backgroundColor: 'white', borderRadius: 5 }}>
          <CustomForms />
        </Flex>
      </Flex>
    </BrandingProvider>
  )
}
