import React from 'react'
import { Flex } from 'mui-blox'
import { AppBar, Toolbar, Button, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Scrollable from '../page/ResponsiveScrollable'
import { useScript } from '~brix/connectors/context/useScript'
import { useQueryParams } from '../routing'
import { sciensio } from '../../config'

export const CustomFormPageMock = () => {
  const classes = useStyles()
  const { formId } = useQueryParams()

  useScript(`${sciensio.apiUrl}/api/custom-forms/form/${formId}/script?mode=test`)

  return (
    <Flex flexDirection='column'>
      <AppBar color='secondary' position='static'>
        <Toolbar>
          <img
            className={classes.logo}
            src='https://intelliweb.network/hub/wp-content/uploads/sites/7/2019/01/generic-company-logo-png-5.png'
          />
          {['Home', 'Find Stuff', 'Learn Stuff', 'Contact Us', 'Register'].map(title => (
            <Button key={title} variant='text' color='inherit'>
              {title}
            </Button>
          ))}
        </Toolbar>
      </AppBar>
      <Scrollable>
        <section className={classes.section}>
          <Flex>
            <Flex flex={1}>
              <Paper className={classes.note} style={{ backgroundColor: 'gray' }}>
                <Typography variant='h4'>People love our stuff!</Typography>
                <Typography>{`People that use our stuff never die! Well, actually they probably do. We aren't around when they die so we really don't know. But if they die, they die happy!`}</Typography>
                <div id='custom-form-container' />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et elit metus. Pellentesque habitant
                  morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut eget dolor sem. Proin
                  feugiat ipsum sed fermentum eleifend. Etiam finibus consectetur nulla non varius. Aliquam quis
                  egestas neque. Cras sit amet viverra justo. Etiam ornare tempus turpis sed euismod. Praesent
                  tincidunt libero nec justo semper, quis condimentum dolor luctus. Mauris sit amet dui nec odio
                  malesuada egestas vehicula id nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  vulputate ipsum ut elit pellentesque, et ullamcorper quam elementum. Nunc euismod purus nec
                  ultrices placerat.
                </p>
              </Paper>
            </Flex>
            {/* <Flex flex={2}>
              <img
                className={classes.section2Image}
                src='https://www.bostoneveningtherapy.com/wp-content/uploads/2018/04/portrait_smile_felicidade_happiness_vida_alegria_sorriso-1024x683.jpg'
              />
            </Flex> */}
          </Flex>
        </section>
      </Scrollable>
    </Flex>
  )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  '@global': {
    body: {
      height: 'unset',
      overflow: 'auto',
    },
  },
  logo: {
    width: 200,
    height: 84,
    marginRight: 16,
  },
  section: {
    padding: spacing(4),
  },
  note: {
    padding: spacing(2),
    flex: '1',
    '& p': {
      paddingTop: spacing(2),
    },
  },
  gridRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: palette.background.paper,
  },
  gridList: {
    height: 450,
  },
  section2Image: {
    width: '100%',
    height: 'auto',
  },
}))
