import React, { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { CustomFormData, CustomFormProps, SaveParticipantsResponse } from './types'
import CustomField from './CustomField'
import { Flex } from 'mui-blox'
import { useQueryParams } from '../routing'
import { Box, Button, CircularProgress, Typography, Grid } from '@mui/material'
import { useCustomForms } from './hooks/useCustomForms'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'

const MIN_HEIGHT = 200

export const CustomForms = () => {
  const { formId } = useQueryParams()
  const { branding } = useEventBranding()
  const { fetchForm, isLoading, saveParticipants } = useCustomForms({ formId })
  const [isComplete, setIsComplete] = useState(false)
  const methods = useForm<CustomFormProps>({ mode: 'onChange' })
  const { handleSubmit, watch, reset } = methods
  const [formData, setFormData] = useState<CustomFormData | null>(null)

  const hasGuest = watch('participant.hasGuest')

  const handleOnSaved = (result?: SaveParticipantsResponse) => {
    const status = result?.status || 0

    if (status >= 200 && status <= 299) {
      reset()
      setIsComplete(true)
    }
  }

  const onSubmit = (data: CustomFormProps) => saveParticipants({ payload: data, onSave: handleOnSaved })

  const handleOnFetchForm = useCallback((data?: CustomFormData) => {
    if (data && data.status >= 200 && data.status <= 299) {
      setFormData(data)
    }
  }, [])

  useEffect(() => {
    if (formId) {
      fetchForm(handleOnFetchForm)
    }
  }, [fetchForm, formId, handleOnFetchForm])

  if (isComplete) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>{formData?.data?.successMessage || 'Thanks for registering!'}</Typography>
      </Box>
    )
  }

  if (!formId) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>No Form Id provided</Typography>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!formData) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>Invalid Form Id</Typography>
      </Box>
    )
  }

  return (
    <Flex
      flexFull
      id='42chat-form-wrapper'
      style={{ background: branding.tertiary as string, width: '100%', padding: 8, overflow: 'auto' }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexColumn justifyContent='center' alignItems='center' width='100%'>
            <Grid container spacing={0}>
              {formData?.data?.participantFields.map(field => (
                <Grid item xs={parseInt(field.layoutWidth || '12')} key={field.name} p={1}>
                  <CustomField {...field} prefix='participant' />
                </Grid>
              ))}
              {hasGuest && (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {formData?.data?.guestFields
                    .filter(field => field.name !== 'hasGuest')
                    .map(field => (
                      <Grid item xs={parseInt(field.layoutWidth || '12')} key={field.name} p={1}>
                        <CustomField {...field} key={field.name} prefix='guest' />
                      </Grid>
                    ))}
                </div>
              )}
            </Grid>

            <Flex justifyContent='flex-end' marginTop={1} width='100%'>
              <Button
                type='submit'
                size='small'
                color='secondary'
                variant='contained'
                style={{ textTransform: 'capitalize' }}
              >
                {formData?.data?.submitLabel || 'Submit'}
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  )
}

export default CustomForms
