const adminRoutes = {
  accept: '/accept',
  adminBotsMonitoring: '/admin/bots/monitoring',
  adminBotsAll: '/admin/bots/all',
  adminBotsQueued: '/admin/bots/queued',
  adminBots: '/admin/bots',
  adminCompanyNumbers: '/admin/companyNumbers',
  adminConversationBuilder: '/admin/botConversations/edit/:conversationId',
  adminConversationList: '/admin/botConversations',
  adminFalsePositives: '/admin/falsePositives',
  adminHome: '/admin',
  adminLogExclusions: '/admin/logExclusions',
  adminLogs: '/admin/logs',
  adminPhoneNumbers: '/admin/phoneNumbers',
  adminProducts: '/admin/products',
  adminShoppinCartsDetail: '/admin/shoppingCarts/:shoppingCartId',
  adminShoppingCarts: '/admin/shoppingCarts',
  adminSupport: '/admin/support',
  adminSupportFilter: '/admin/support/:filter',
  adminSupportItem: '/admin/support/:filter/:id',
  adminUpcomingNotifications: '/admin/upcomingNotifications',
  aiAgentPool: '/admin/aiAgentPool',
  answerReview: '/admin/answerReview',
  assetManager: '/admin/orgs/:orgId/events/:eventId/assetManager',
  bot: '/admin/orgs/:orgId/bots/:botId',
  botAddons: '/admin/orgs/:orgId/bots/:botId/addons',
  botAdminTesting: '/admin/orgs/:orgId/bots/:botId/botTesting',
  botAnswerReviews: '/admin/orgs/:orgId/bots/:botId/botAnswerReviews',
  botAssets: '/admin/orgs/:orgId/bots/:botId/assets/:assetType',
  botBranding: '/admin/orgs/:orgId/bots/:botId/branding',
  botChannels: '/admin/orgs/:orgId/bots/:botId/channels',
  botComponents: '/admin/orgs/:orgId/bots/:botId/componentType/:type',
  botConExes: '/admin/orgs/:orgId/bots/:botId/conExes',
  botConfiguration: '/admin/orgs/:orgId/bots/:botId/configure/:type?',
  botConnectors: '/admin/orgs/:orgId/bots/:botId/connectors',
  botCustomerDelivery: '/admin/orgs/:orgId/bots/:botId/delivery',
  botDeployments: '/admin/orgs/:orgId/bots/:botId/deployments',
  botEntities: '/admin/orgs/:orgId/bots/:botId/entities',
  botEvent: '/admin/orgs/:orgId/bots/:botId/event',
  botExclusions: '/admin/orgs/:orgId/bots/:botId/exclusions',
  botFalsePositives: '/admin/orgs/:orgId/bots/:botId/support/falsePositives',
  botIdks: '/admin/orgs/:orgId/bots/:botId/idks',
  botIntegrations: '/admin/orgs/:orgId/bots/:botId/integrations',
  botIntents: '/admin/orgs/:orgId/bots/:botId/intents',
  botJokes: '/admin/orgs/:orgId/bots/:botId/jokes',
  botList: '/admin/orgs/:orgId/bots',
  botMessageLog: '/admin/orgs/:orgId/bots/:botId/messageLog',
  botMetrics: '/admin/orgs/:orgId/bots/:botId/metrics',
  botNotificationLogs: '/admin/orgs/:orgId/bots/:botId/notificationLogs',
  botNotifications: '/admin/orgs/:orgId/bots/:botId/notifications',
  botParticipants: '/admin/orgs/:orgId/bots/:botId/participants',
  botQuestionnaire: '/admin/orgs/:orgId/bots/:botId/questionnaire',
  botQuestions: '/admin/orgs/:orgId/bots/:botId/questions',
  botQuestionsAddHoc: '/admin/orgs/:orgId/bots/:botId/ad-hoc-questions',
  botShortLinkLog: '/admin/orgs/:orgId/bots/:botId/shortLinkLog',
  botEventData: '/admin/orgs/:orgId/bots/:botId/events/:eventId/data',
  botsRoot: '/admin/orgs/:orgId/bots',
  botSupport: '/admin/orgs/:orgId/bots/:botId/support',
  botTesting: '/admin/orgs/:orgId/events/:eventId/botTesting',
  dashboard: '/admin/orgs/:orgId/events/:eventId/dashboard',
  event: '/admin/orgs/:orgId/events/:eventId',
  eventBranding: '/admin/orgs/:orgId/events/:eventId/branding',
  eventCreateWizard: '/admin/orgs/:orgId/events/createWizard',
  eventExconData: '/admin/orgs/:orgId/events/:eventId/data',
  eventImages: '/admin/orgs/:orgId/events/:eventId/images',
  eventIntegrations: '/admin/orgs/:orgId/events/:eventId/integrations',
  eventLinks: '/admin/orgs/:orgId/events/:eventId/links',
  eventSupport: '/admin/orgs/:orgId/events/:eventId/support', //to be changed to customer after MVP
  eventSupportFilter: '/admin/orgs/:orgId/events/:eventId/support/:filter', //to be changed to customer after MVP
  eventSupportFilterItem: '/admin/orgs/:orgId/events/:eventId/support/:filter/:id', //to be changed to customer after MVP
  falsePositives: '/admin/orgs/:orgId/events/:eventId/falsePositives',
  history: '/admin/history',
  idks: '/admin/orgs/:orgId/events/:eventId/idks',
  intentHitCountLog: '/admin/orgs/:orgId/bots/:botId/intentHitCountLog',
  libraryHome: '/admin/library',
  librarySynonymGroups: '/admin/library/synonymGroup',
  libraryTrainingPhrases: '/admin/library/trainingPhrase',
  libraryResponses: '/admin/library/response',
  libraryNotifications: '/admin/library/notification',
  libraryQuestions: '/admin/library/question',
  links: '/admin/orgs/:orgId/events/:eventId/links',
  liveEvents: '/admin/liveEvents/:filter',
  notifications: '/admin/orgs/:orgId/events/:eventId/notifications',
  org: '/admin/orgs/:orgId',
  orgs: '/admin/orgs',
  participants: '/admin/orgs/:orgId/events/:eventId/participants',
  participantsRegistrationEdit: '/admin/orgs/:orgId/events/:eventId/participants/registration/:id',
  promoCodes: '/admin/promoCodes',
  response: '/admin/orgs/:orgId/events/:eventId/responses/:intentId',
  responseExtension: '/admin/orgs/:orgId/events/:eventId/responses/:intentId/extension',
  responses: '/admin/orgs/:orgId/events/:eventId/responses',
  trafficController: '/admin/trafficController',
  training: '/admin/orgs/:orgId/events/:eventId/botTraining',
  trainingResults: '/admin/orgs/:orgId/events/:eventId/botTraining/:trainingId',
  users: '/admin/orgs/:orgId/users',
  eventDefaultResponses: '/admin/orgs/:orgId/bots/:botId/defaultResponses',
  customFormsEditor: '/admin/orgs/:orgId/bots/:botId/custom-forms',
}

export const routes = {
  ...adminRoutes,
  home: '/',
  login: '/login',
  shoppingCart: '/eventBot/:slug?',
  shoppingCartBotSurvey: '/eventBot/botSurvey',
  customerLoggedInShoppingCartRedirect: '/eventBot/checkout',
  shoppingCartCreateAccount: '/eventBot/checkout/account',
  shoppingCartQualifySurvey: '/eventBot/getQualified/:slug?',
  shoppingCartQualifyResults: '/eventBot/qualifyResults',
  shoppingCartSummary: '/eventBot/cart',
  shoppingCartThankYou: '/eventBot/thankyou',
  shoppingCartShared: '/eventBot/shared/:shoppingCartId',
  // dev routes
  componentLibHome: '/admin/component-library',
  sandbox: '/admin/sandbox',
  socketManager: '/admin/sockets',
}
